import {
	GetAllInsuranceProductsForPortalQuery,
	GetAllTargetGroupsQuery,
	GetPortalSettingsQuery,
	SiteLocale,
} from "src/__generated__/cms-schema.codegen";
import { HeaderLinks } from "src/types";
import config from "../../../funk-experts.config.json";
import { BlogpostPageProps } from "../cms/blogpost";
import { fromEntries, isRecord, truthy } from "../utils";

export type PortalSettings = {
	products:
		| GetAllInsuranceProductsForPortalQuery["allInsuranceProducts"]
		| null;
	header: HeaderLinks;
	content:
		| NonNullable<GetPortalSettingsQuery["brand"]>["portalContent"]
		| null;
	onboarding:
		| NonNullable<GetPortalSettingsQuery["brand"]>["portalOnboarding"]
		| null;
};

export type BrokerPortalSettings = {
	products:
		| GetAllInsuranceProductsForPortalQuery["allInsuranceProducts"]
		| null;
	header: HeaderLinks;
	content:
		| NonNullable<GetPortalSettingsQuery["brand"]>["brokerPortalContent"]
		| null;
	onboarding:
		| NonNullable<GetPortalSettingsQuery["brand"]>["brokerPortalOnboarding"]
		| null;
};

export type OnboardingType =
	| NonNullable<PortalSettings["onboarding"]>["slides"]
	| undefined;

export type PortalType = "portal" | "broker-portal" | null;

export type TargetGroup = {
	id: string;
	slug: string;
	contact: GetAllTargetGroupsQuery["allTargetGroups"][number]["contact"];
} | null;

export type Blogpost = NonNullable<BlogpostPageProps>["blogpost"] | null;

export type ProductSlug = string | null;

export type Products = BrokerPortalSettings["products"];

export const isSSR = !(
	// eslint-disable-next-line @typescript-eslint/prefer-optional-chain
	(typeof window !== "undefined" && window.document.createElement)
);

export const showMaintenanceMode = (
	maintenanceMode: boolean,
	isPreview: boolean,
) => {
	return maintenanceMode && !isPreview && !process.env.FEX_DEBUG_MODE;
};
export const maskCdnUrl = (value: string, baseUrl?: string) => {
	for (const mask of config.masks) {
		if (value.includes(mask.host)) {
			const re = new RegExp(`https*://${mask.host}/`, "g");

			return value.replace(
				re,
				[baseUrl, mask.path].filter(truthy).join(""),
			);
		}
	}

	return value;
};

export const maskCdnUrlsAnywhere = <T>(o: T): T => {
	if (typeof o === "string") {
		return maskCdnUrl(o) as T;
	}

	if (typeof o !== "object") {
		return o;
	}

	if (isRecord(o)) {
		return fromEntries(
			Object.entries(o).map(([k, v]) => [k, maskCdnUrlsAnywhere(v)]),
		) as T;
	}

	if (Array.isArray(o)) {
		return o.map(maskCdnUrlsAnywhere) as T;
	}

	return o;
};

export const preventClientSideUse = () => {
	if (!isSSR && !process.env.JEST_WORKER_ID) {
		throw new Error("Do not use these utils client side!");
	}
};

// 🥦
export const getThnovoSlug = (locale: SiteLocale) => {
	return locale === SiteLocale.de ? "hausverwalter" : "thnovo";
};

export const getThnovoProductSlug = (locale: SiteLocale) => {
	return locale === SiteLocale.de ? "iak-police" : "iak-police";
};

// 🔬 TBD: Please evaluate
