"use client";

import { useSiteSettings } from "./hooks";

export const availableFlags = {
	"portal-dynamic-factors": false,
	"portal-cancellation-feedback": false,
};

type FeatureFlag = keyof typeof availableFlags;

export type FeatureFlags = Record<FeatureFlag, boolean>;

export const useFeatureFlag = (flag: FeatureFlag) => {
	const { featureFlags } = useSiteSettings();

	const flags = {
		...featureFlags,
		...getFeatureFlagOverrides(),
	};

	return flags[flag];
};

const getFeatureFlagOverrides = () => {
	let featureFlagOverrides = {};

	try {
		featureFlagOverrides = process.env.OVERRIDE_FEATURE_FLAGS
			? JSON.parse(process.env.OVERRIDE_FEATURE_FLAGS)
			: {};
	} catch {
		// do nothing
	}

	return featureFlagOverrides;
};

// 🔬 jest unit tested
